<script lang="ts">
import { useRoute } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperControl from "../../../components/swiper-control.vue";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { inject, ref } from "vue";
import { occurrencesKey, schedulesKey } from "../../../keys";

export default {
  name: "ManageOccurrences",
  components: {
    Icon,
    Navbar,
    Swiper,
    SwiperSlide,
    SwiperControl,
  },
  setup() {
    const route = useRoute();
    const swiper = ref(null);
    const tabs = ["SCHEDULES", "OCCURRENCES", "CALENDAR"] as const;
    const activeTab = ref(0);
    const schedules = inject(schedulesKey);
    const occurrences = inject(occurrencesKey);

    return {
      route,
      swiper,
      tabs,
      activeTab,
      schedules,
      occurrences,
    };
  },
};
</script>

<template>
  <portal to="header">
    <Navbar title="Add New Tour" :solid="true">
      <template #left>
        <button
          class="btn btn-circle bg-base-100"
          @click="
            $router.push({
              name: 'editTourOverview',
              params: { tourId: route.params.tourId },
            })
          "
        >
          <Icon id="arrow-left" />
        </button>
      </template>
      <template #bottom>
        <div class="tabs tabs-bordered px-3">
          <a
            @click="activeTab = 0"
            :class="{ 'tab-active': activeTab === 0 }"
            class="tab font-medium"
            >Schedules
          </a>
          <a
            @click="activeTab = 1"
            :class="{ 'tab-active': activeTab === 1 }"
            class="tab font-medium"
            >Occurrences
          </a>
          <a
            @click="activeTab = 2"
            :class="{ 'tab-active': activeTab === 2 }"
            class="tab font-medium"
            >Calendar
          </a>
        </div>
      </template>
    </Navbar>
  </portal>

  <div class="flex flex-col justify-between h-full">
    <swiper
      :ref="swiper"
      @slide-change="({ activeIndex }) => (activeTab = activeIndex)"
      :centered-slides="true"
    >
      <SwiperControl :selected="activeTab"></SwiperControl>
      <swiper-slide :key="tabs[0]">
        <div class="flex flex-col p-3 h-full overflow-y-scroll">
          <div v-for="(schedule, i) in schedules" :key="schedule.id">
            <div class="flex flex-row">
              <div class="flex flex-col grow">
                <div>{{ schedule.dtStart.dayjs.format("llll") }}</div>
                <div>{{ schedule.getDuration() }}</div>
                <p class="m-0">{{ schedule.rrule.toText() }}</p>
              </div>
              <div class="flex flex-col justify-center">
                <Icon id="arrow-right-s" class="ml-3" />
              </div>
            </div>
            <div v-show="i + 1 < schedules.length" class="divider my-2"></div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide :key="tabs[1]">
        <div class="flex flex-col p-3 h-full overflow-y-scroll">
          <div v-for="(occurrence, i) in occurrences" :key="i">
            <div class="flex flex-row">
              <div
                class="btn btn-neutral btn-square aspect-square h-auto min-h-0 mr-3"
              >
                {{ occurrence.dtStart.dayjs.format("MMM") }}<br />{{
                  occurrence.dtStart.dayjs.format("DD")
                }}
              </div>
              <div class="flex flex-col grow">
                <div>{{ occurrence.dtStart.dayjs.format("llll") }}</div>
                <div>{{ occurrence.getDuration() }}</div>
              </div>
              <div class="flex flex-col justify-center">
                <Icon id="arrow-right-s" class="ml-3" />
              </div>
            </div>
            <div v-show="i + 1 < occurrences.length" class="divider my-1"></div>
          </div>
        </div>
        ></swiper-slide
      >
      <swiper-slide :key="tabs[2]">
        <div class="flex flex-col p-3 h-full overflow-y-scroll"></div
      ></swiper-slide>
    </swiper>

    <div class="border-t border-base-300 shadow-xl p-3 relative">
      <button
        class="btn btn-primary btn-block"
        @click="
          $router.push({
            name: 'editTourAvailability',
            params: { tourId: route.params.tourId },
          })
        "
      >
        Continue
      </button>

      <div
        class="absolute right-3 bottom-full mb-3 flex flex-col space-y-3"
        style="z-index: 999"
      >
        <button
          @click="
            $router.push({
              name: 'editTourOccurrence',
              params: { tourId: route.params.tourId },
            })
          "
          class="btn btn-circle shadow-lg bg-base-100"
        >
          <Icon id="add" height="20px" width="20px" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper {
  max-width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.swiper-wrapper {
  height: 100% !important;
}
</style>
