@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  gap: 0.25rem;
}

svg.text-warning {
  fill: oklch(var(--wa));
}

svg.text-success {
  fill: oklch(var(--su));
}

.nav {
  @apply bg-transparent;
}

.nav--fixed {
  @apply bg-base-100 shadow;
}

.nav--fixed .btn {
  @apply btn-ghost;
}

.btm-nav {
  @apply static;
  height: 4.5rem;
}

.btm-nav > *:where(.active) {
  border-color: oklch(var(--p));
}

.btm-nav > * {
  row-gap: 0;
}

.btm-nav > button:not(:where(.active)) > svg {
  fill: oklch(var(--p) / 0.6);
}

.btm-nav > button:not(:where(.active)) > .btm-nav-label {
  color: oklch(var(--p) / 0.6);
}

.btm-nav svg {
  fill: oklch(var(--p));
}

.btm-nav-label {
  @apply text-sm text-primary;
}

.card-compact .card-title {
  @apply prose-base leading-5;
}

.card-compact .card-body {
  @apply p-3 gap-0;
}

.card .card-body .table tr:last-child td {
  @apply pb-0;
}

textarea:focus,
textarea:focus-within,
.input:focus,
.input:focus-within {
  @apply outline-offset-0 !important;
}

label.label {
  @apply px-0;
}

.tabs {
  @apply space-x-4;
}

.tabs-bordered .tab:not(.tab-active) {
  border-color: transparent;
}

.tab {
  @apply px-0 !important;
}

.tab.tab-active:not(.tab-disabled):not([disabled]) {
  border-color: oklch(var(--su));
  transition: border-color 0.3s ease-in-out;
}

.badge {
  @apply px-1 text-xs !important;
  border-color: oklch(var(--bc) / var(--tw-border-opacity));
  background-color: oklch(var(--bc) / var(--tw-text-opacity));
  color: oklch(var(--b1) / var(--tw-bg-opacity));
}

.badge-primary {
  border-color: oklch(var(--pc) / var(--tw-border-opacity));
  background-color: oklch(var(--pc) / var(--tw-text-opacity));
  color: oklch(var(--p) / var(--tw-bg-opacity));
}

:where(.timeline > li) {
  grid-gap: 0rem 0.25rem;
  grid-template-columns: var(--timeline-col-start, minmax(0, auto)) auto var(
      --timeline-col-end,
      minmax(0, 1fr)
    );
}

.timeline-start,
.timeline-end {
  margin: 0;
}

.timeline-end {
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.timeline-vertical > li > hr {
  width: 0.2rem;
}

.maplibregl-ctrl-bottom-left {
  @apply bottom-0 w-full !important;
}

.maplibregl-ctrl-top-left {
  @apply top-20 mt-2 !important;
}

.maplibregl-ctrl-attrib {
  @apply box-border w-full my-0 px-3 rounded-none !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.map-marker {
  @apply bg-primary rounded-full w-3.5 h-3.5;
  display: block;
  border: none;
  cursor: pointer;
  padding: 0;
}

.swiper-pagination-horizontal {
  @apply w-full !important;
}

.swiper-pagination-horizontal .swiper-pagination-bullet {
  @apply w-2.5 h-1.5 rounded-md bg-base-100 opacity-100 transform-none shadow-sm !important;
}

.swiper-pagination-horizontal .swiper-pagination-bullet-active {
  @apply w-7 !important;
}

.van-slider__bar {
  /* background: oklch(var(--p)) !important; */
}

.van-popup.van-popup--bottom {
  @apply rounded-t-lg;
}

.van-password-input__security
  .van-password-input__item:first-child:not(:last-child),
.van-password-input__security
  *:first-child:not(:last-child)
  .van-password-input__item {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-right-width: 0;
}

.van-password-input__security
  .van-password-input__item:last-child:not(:first-child),
.van-password-input__security
  *:last-child:not(:first-child)
  .van-password-input__item {
  border-end-start-radius: 0;
  border-start-start-radius: 0;
}

.van-password-input__security
  .van-password-input__item:not(:first-child):not(:last-child),
.van-password-input__security
  *:not(:first-child):not(:last-child)
  .van-password-input__item {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  border-start-start-radius: 0;
  border-right-width: 0;
}

.van-password-input__item {
  @apply input input-bordered;
}
