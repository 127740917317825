<script lang="ts">
import { Ref, ref, toRef, watch } from "vue";
import { useFileDialog } from "@vueuse/core";
import { useSortable } from "@vueuse/integrations/useSortable";
import Icon from "./icon/index.vue";
import { useUploadDAO } from "../entities/upload";
import { useImageDAO } from "../entities/image";
import Image from "../components/image.vue";

export default {
  name: "PhotoManager",
  components: {
    Icon,
    Image,
  },
  props: {
    modelValue: {
      type: Array<string>,
    },
    accountId: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const modelValue = toRef(props, "modelValue");
    const photoEl = ref<HTMLElement | null>(null);
    const images: Ref<
      Array<ReturnType<typeof useImageDAO> | ReturnType<typeof useUploadDAO>>
    > = ref(
      modelValue.value.map((imageId) => {
        console.log("test");
        const upload = useImageDAO();
        upload.value.remote.$patch({ id: imageId });
        return upload;
      }),
    );

    /* watch(
      modelValue,
      (newModelValue) => {
        images.value.push(
          ...newModelValue.map((imageId) => {
            const upload = useImageDAO();
            upload.value.remote.$patch({ id: imageId });
            return upload;
          }),
        );
      },
      { immediate: true },
    ); */

    watch(
      images,
      (newImages) => {
        ctx.emit(
          "update:modelValue",
          newImages.map((image) => image.value.remote.id),
        );
      },
      { deep: true },
    );

    const { files, open, reset, onChange } = useFileDialog({
      accept: "image/*",
    });

    onChange(async (files) => {
      if (files) {
        for (const file of files) {
          const upload = useUploadDAO();
          const image = useImageDAO();

          upload.value.local.$patch({
            accountId: props.accountId,
            file: file,
          });

          images.value.push(upload);

          await upload.value.$create();

          image.value.local.$patch({
            accountId: upload.value.local.accountId,
            uploadId: upload.value.local.id,
          });

          await image.value.$create();
        }
      }
    });

    const sortable = useSortable(photoEl, images, {
      animation: 150,
    });

    return {
      photoEl,
      images,
      files,
      open,
      reset,
    };
  },
};
</script>

<template>
  <div class="relative">
    <div
      class="absolute top-0 left-0 w-full grid grid-cols-3 grid-flow-row gap-4 mb-6"
    >
      <div
        @click="() => open()"
        class="aspect-square rounded border-dashed border border-base-content flex items-center justify-center p-3"
      >
        <Icon id="add" width="24px" height="24px" />
      </div>
    </div>
    <div class="grid grid-cols-3 grid-flow-row gap-4 mb-6" ref="photoEl">
      <div v-if="!images || images.length === 0" class="avatar">
        <div class="aspect-square w-full"></div>
      </div>
      <div v-for="image in images" :key="image.value.local.id" class="avatar">
        <div
          v-if="image.value.type === 'upload'"
          class="aspect-square border border-solid rounded relative shadow"
        >
          <img :src="image.value.local.objectUrl" />
          <div
            v-if="image.value.local.isUploading"
            class="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-white/50"
          >
            <div
              class="radial-progress"
              :style="{ '--value': image.value.local.uploadProgress }"
              style="--size: 3rem"
              role="progressbar"
            ></div>
          </div>
        </div>
        <div
          v-else
          class="aspect-square border border-solid rounded relative shadow"
        >
          <Image :id="image.value.remote.id" />
        </div>
      </div>
    </div>
    <div class="prose">
      <h5 class="text-center text-neutral-400">
        Drag and drop to reorder photos
      </h5>
    </div>
  </div>
</template>

<style scoped>
.avatar:nth-child(1) {
  grid-column-start: 2;
}
</style>
